/*------------------------------------*\
    #breadcrumb-beta
\*------------------------------------*/

/*<-- GLOBAL -->*/
.breadcrumb-beta{
  position: absolute;
  top: 120px;
  left: calc((100% - 1170px) / 2);
  z-index: 1;

  .as-breadcrumb-container {
    display: block;
    height: auto;
    text-align: center;
    font-family: $museo;

    ul.as-breadcrumb {
      @include clearfix;
      width: auto;
      display: inline-block;
      margin: 0px auto;
      padding: 12px 15px 8px;

      li {
        float: left;
        padding-right: 32px;
        list-style: none;
        position: relative;

        &:last-child {
          padding-right: 0px;

          &:after {
            display: none;
          }
        }

        &:after {
          @extend .as-icon.arrow-right-small;
          content: '';
          display: block;
          background-repeat: no-repeat;
          position: absolute;
          top: 6px;
          right: 8px;
        }

        a {
          color: $secondary-site-color;
          font-size: 14px;
        }
      }
    }
  }
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .breadcrumb-beta{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .breadcrumb-beta{
    padding-left: 0px;
    top: 70px;
    left: 24px !important;

    .as-breadcrumb-container {
      ul.as-breadcrumb {
        padding-left: 0px;

        li {
          a {
            font-size: 12px;
          }
        }
      }
    }
  }
}
/*<-- MD -->*/
@media (max-width: $screen-md-max) {
  .breadcrumb-beta{
    left: calc((100% - 970px) / 2);
  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .breadcrumb-beta{

  }
}
