/*------------------------------------*\
    #modal-beta
\*------------------------------------*/

/*<-- GLOBAL -->*/
.modal-beta{
	width: 100%;

	.modal {
	  text-align: center;
	  width: 100%;
	  overflow: auto;
	}

	.modal-dialog {
	  display: inline-block;
	  text-align: left;
	  vertical-align: middle; 
	}

	.modal-content {
		background: transparent;
		box-shadow: none;
		border: 0px;
		width: 100%;
	}

	.modal-body {
		padding: 0px;
		width: 100%;
	}

	.modal-header {
		border: 0px;
    	padding: 0px;
    	width: 100%;
	}

	.modal-header .close {
		margin-top: 0px;
	    position: relative;
	    top: -30px;
	    color: white;
	    font-size: 40px;
	    opacity: 1;
	    outline: none;
	    
	    i {
	    	text-shadow: none;
	    }
	}

	.owl-nav .owl-prev {
		position: absolute;
	    top: 50%;
	    color: white;
	    font-size: 72px;
	    transform: translateY(-50%);
	    left: -80px;
	}

	.owl-nav .owl-next {
		position: absolute;
	    top: 50%;
	    color: white;
	    font-size: 72px;
	    transform: translateY(-50%);
	    right: -80px;
	}
}

// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .modal-beta{
  	max-width: 320px;
  	max-height: 568px;
  	overflow: hidden;
  }

  .modal-beta .modal-dialog {
  	width: calc(100% - 20px);
  	top: 50%;
    transform: translateY(-50%) !important;
  }

  .modal-beta .owl-nav {
	display: none;
  }
}
/*<-- SM -->*/
@media (min-width: $screen-sm-min) {
  .modal-beta{

  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .modal-beta{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .modal-beta{

  }
}
