@font-face {
	font-family: "icons";
	src: url('../fonts/icons/icons.eot');
	src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
		url('../fonts/icons/icons.woff2') format('woff2'),
		url('../fonts/icons/icons.woff') format('woff'),
		url('../fonts/icons/icons.ttf') format('truetype'),
		url('../fonts/icons/icons.svg#icons') format('svg');
}

@mixin icon-styles {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == arrow-down {
		$char: "\E001";
	}
	@if $filename == check {
		$char: "\E002";
	}
	@if $filename == close {
		$char: "\E003";
	}
	@if $filename == error {
		$char: "\E004";
	}
	@if $filename == left-arrow-slider {
		$char: "\E005";
	}
	@if $filename == right-arrow-slider {
		$char: "\E006";
	}
	@if $filename == search {
		$char: "\E007";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-arrow-down {
	@include icon(arrow-down);
}
.icon-check {
	@include icon(check);
}
.icon-close {
	@include icon(close);
}
.icon-error {
	@include icon(error);
}
.icon-left-arrow-slider {
	@include icon(left-arrow-slider);
}
.icon-right-arrow-slider {
	@include icon(right-arrow-slider);
}
.icon-search {
	@include icon(search);
}
