/*------------------------------------*\
    #promo-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.promo-alpha{
	width: 100%;
	height: 270px;
	margin-top: -100px;
  font-family: $museo;

	.promo-alpha-container {
		width: 100%;
		height: 270px;
		overflow: hidden;
		position: absolute;
		top: 0px;
		left: 0px;

		.promo-alpha-image {
			@include all-center;
      width: 100%;
			overflow: hidden;
			position: absolute;

			img {
        min-width: 100%;
				display: block;
			}
		}

		.promo-alpha-title {
			@include horizontal-center;
			width: 100%;
			max-width: 640px;
			margin: 0 auto;
			font-size: 42px;
			font-weight: 500;
			color: $secondary-site-color;
			text-align: center;
			position: absolute;
			top: 128px;
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .promo-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .promo-alpha{
  	height: 230px;
  	margin-top: 0px;
  	position: relative;

		.promo-alpha-container {
			height: 230px;
			
			.promo-alpha-title {
				@include all-center;
				font-size: 32px;
				padding: 0 48px;
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .promo-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .promo-alpha{

  }
}
