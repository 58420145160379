.as-image {
	display: block;
	background-repeat: no-repeat;

	&.down {
		width: 14px;
		height: 10px;
		background-image: url('../images/tooltip-alpha/down.png');
	}

	&.logo {
		width: 110px;
		height: 51px;
		background-image: url('../images/logo.png');
	}

	&.tay-group {
		width: 82px;
		height: 27px;
		background-image: url('../images/tay-group.png');
	}

	&.four-seasons {
		width: 101px;
		height: 63px;
		background-image: url('../images/footer-alpha/four-seasons.png');
	}

	&.four-seasons-sultan {
		width: 101px;
		height: 63px;
		background-image: url('../images/footer-alpha/four-seasons-sultan-ahmet.png');
	}

	&.onalti-dokuz {
		width: 176px;
		height: 26px;
		background-image: url('../images/footer-alpha/onalti-dokuz.png');
	}

	&.academia {
		width: 102px;
		height: 28px;
		background-image: url('../images/footer-alpha/academia.png');
	}

	&.gri-logo {
		width: 65px;
		height: 12px;
		background-image: url('../images/gri-logo.png');
	}
}

.as-icon {
	display: block;
	background-repeat: no-repeat;

	&.accordion-arrow-down {
		width: 15px;
		height: 9px;
		background-image: url('../png/accordion-arrow-down.png');
	}

	&.accordion-arrow-up {
		width: 15px;
		height: 9px;
		background-image: url('../png/accordion-arrow-up.png');
	}

	&.arrow-down {
		width: 11px;
		height: 7px;
		background-image: url('../png/arrow-down.png');
	}

	&.arrow-up {
		width: 15px;
		height: 9px;
		background-image: url('../png/arrow-up.png');
	}

	&.arrow-right-small {
		width: 14px;
		height: 9px;
		background-image: url('../png/arrow-right-small.png');
	}

	&.arrow-left-small {
		width: 14px;
		height: 9px;
		background-image: url('../png/arrow-left-small.png');
	}

	&.attach {
		width: 20px;
		height: 22px;
		background-image: url('../png/attach.png');
	}

	&.close {
		width: 24px;
		height: 24px;
		background-image: url('../png/close.png');
	}

	&.close-gold {
		width: 20px;
		height: 20px;
		background-image: url('../png/close-gold.png');
	}

	&.download {
		width: 19px;
		height: 19px;
		background-image: url('../png/download.png');
	}

	&.document {
		width: 19px;
		height: 19px;
		background-image: url('../png/document.png');
	}

	&.hamburger {
		width: 30px;
		height: 13px;
		background-image: url('../png/hamburger.png');
	}

	&.hamburger-close {
		width: 23px;
		height: 23px;
		background-image: url('../png/hamburger-close.png');
	}

	&.search {
		width: 17px;
		height: 17px;
		background-image: url('../png/search.png');
	}

	&.search-alternative {
		width: 17px;
		height: 17px;
		background-image: url('../png/search-alternative.png');
	}

	&.breadcrumb-arrow-right {
		width: 4px;
		height: 7px;
		background-image: url('../png/breadcrumb-arrow-right.png');
	}

	&.carousel-arrow-right {
		width: 27px;
		height: 17px;
		background-image: url('../png/carousel-arrow-right.png');
	}

	&.carousel-arrow-left {
		width: 27px;
		height: 17px;
		background-image: url('../png/carousel-arrow-left.png');
	}

	&.facebook {
		width: 6px;
		height: 12px;
		background-image: url('../png/facebook.png');
	}

	&.fax {
		width: 25px;
		height: 24px;
		background-image: url('../png/fax.png');
	}

	&.link {
		width: 20px;
		height: 20px;
		background-image: url('../png/link.png');
	}

	&.mail {
		width: 24px;
		height: 16px;
		background-image: url('../png/mail.png');
	}

	&.pin {
		width: 20px;
		height: 25px;
		background-image: url('../png/pin.png');
	}

	&.phone {
		width: 24px;
		height: 24px;
		background-image: url('../png/phone.png');
	}

	&.popup-close {
		width: 24px;
		height: 24px;
		background-image: url('../png/popup-close.png');
	}

	&.twitter {
		width: 12px;
		height: 10px;
		background-image: url('../png/twitter.png');
	}

	&.video {
		width: 22px;
		height: 19px;
		background-image: url('../png/video.png');
	}

	&.video-gold {
		width: 22px;
		height: 19px;
		background-image: url('../png/video-gold.png');
	}

	&.visit {
		width: 20px;
		height: 20px;
		background-image: url('../png/visit.png');
	}

	&.youtube {
		width: 9px;
		height: 11px;
		background-image: url('../png/youtube.png');
	}

	&.tooltip-arrow-right {
		width: 18px;
		height: 11px;
		background-image: url('../png/tooltip-arrow-right.png');
	}

	&.tooltip-arrow-left {
		width: 18px;
		height: 11px;
		background-image: url('../png/tooltip-arrow-left.png');
	}
}
