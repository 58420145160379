/*------------------------------------*\
    #tooltip-beta
\*------------------------------------*/

/*<-- GLOBAL -->*/
.tooltip-beta{
	@include horizontal-center;
	width: 307px;
	display: none;
	position: absolute;
	bottom: 60px;

	&:after {
		@include horizontal-center;
		@extend .as-image.down;
		content: '';
		position: absolute;
		bottom: -10px;
	}

	.as-tooltip-container {
		padding: 16px;
		font-family: $museo;
		background-color: $secondary-site-color;

		.as-input-container {
			position: relative;

			.as-bulten-input {
				width: 100%;
				height: 45px;
				border: 1px solid #f1f1f1;
				padding: 0 64px 0 16px;
				outline: none;
				color: #080e1b;
			}

			.as-bulten-button {
				width: 45px;
				height: 45px;
				display: block;
				background-color: $primary-site-color;
				border: none;
				outline: none;
				position: absolute;
				top: 0px;
				right: 0px;

				.as-icon {
					@include all-center;
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .tooltip-beta{

  }
}
/*<-- SM -->*/
@media (min-width: $screen-sm-min) {
  .tooltip-beta{

  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .tooltip-beta{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .tooltip-beta{

  }
}
