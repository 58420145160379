/*------------------------------------*\
    #bar-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
$bar-height: 40px;

.bar-alpha{
	width: 100%;
	position: absolute;
	left: 0px;
	bottom: 32px;
	z-index: 2;

	.as-bar-alpha-container {
		height: $bar-height;
		background-color: $primary-site-color;
		color: $secondary-site-color;

		.as-bar-alpha-menu {
			@include clearfix;
			width: 100%;
			height: 100%;
			margin: 0px;
			padding: 0px;

			li {
				width: auto;
				height: $bar-height;
				padding: 0 16px;
				line-height: $bar-height;
				font-size: 15px;
				font-family: $museo;
				font-weight: 500;
				list-style: none;
				float: left;
				position: relative;

				&.active {
					background-color: #002e57;
				}
				
				&:before {
					@include opacity(.1);
					content: '';
					width: 1px;
					height: 100%;
					display: block;
					background-color: $secondary-site-color;
					position: absolute;
					top: 0px;
					right: 0px;
				}

				&:first-child {
					padding-left: 0px;
				}

				&:last-child {
					&:before {
						display: none;
					}
				}

				.tooltip-alpha {
					@include opacity(1);
					max-height: 252px;
					overflow: hidden;
					
					li {
						height: auto;
						padding: 0px;
						line-height: initial;
					}
				}

				a {
					width: 100%;
					height: 100%;
					display: block;
					color: $secondary-site-color;

					&:hover {
						border-bottom: 1px solid #144977;
					}
				}
			}
		}
	}

	.as-social-container {
		@include clearfix;
		width: 220px;
		margin: 0 -15px;
		float: right;

		.as-bulten {
			width: 100px;
			height: $bar-height;
			line-height: $bar-height;
			text-align: center;
			float: left;
			position: relative;

			a {
				color: $secondary-site-color;
				font-size: 14px;
				font-family: $museo;

				&:hover {
					border-bottom: 1px solid #144977;
				}
			}
		}

		ul.as-social {
			@include clearfix;
			margin: 0px;
			padding: 0px;
			float: right;

			li {
				width: $bar-height;
				height: $bar-height;
				float: left;
				list-style: none;

				a {
					width: $bar-height;
					height: $bar-height;
					display: block;
					background-color: #002e57;
					border-right: 1px solid #00294d;
					position: relative;
					
					.as-icon {
						@include all-center;
					}
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .bar-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .bar-alpha{
  	width: 100%;
  	position: initial;

		.as-bar-alpha-container {
			width: 100%;
			height: auto;

			.col-xs-12 {
				padding: 0px;
			}

			.as-bar-alpha-menu {
				height: auto;
				position: relative;
    		z-index: 1;

				li {
					width: 100%;
					height: auto;
					padding-left: 0px;
					padding-right: 0px;
					float: none;
					text-align: center;
					font-size: 15px;
					line-height: 48px;

					&.active {
						background-color: initial;
					}

					&:before {
						width: 100%;
						height: 1px;
						top: initial;
						right: initial;
						left: 0px;
						bottom: 0px;
					}
				}
			}

			.as-social-container {
				display: none;
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .bar-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .bar-alpha{

  }
}
