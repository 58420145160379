/*------------------------------------*\
    #tooltip-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.tooltip-alpha{
	@include horizontal-center;
	@include opacity(0);
	width: 307px;
	display: none;
	padding-bottom: 20px;
	font-family: $museo;
	position: absolute;
	bottom: 40px;

	&:after {
		@include horizontal-center;
		@extend .as-image.down;
		content: '';
		position: absolute;
		bottom: 10px;
	}

	.as-tooltip-container {
		padding: 16px;
		background-color: $secondary-site-color;
	}

	.as-tooltip-carousel {
		max-height: 200px;
		margin: 0px;
		padding: 0px;
		overflow: hidden;

		li {
			list-style: none;

			a {
				&:hover {
					border-bottom: none !important;
				}
			}
		}

		.owl-nav {
			width: 100%;
			height: 0px;
			position: absolute;
			top: 70px;

			.owl-next,
			.owl-prev {
				@include vertical-center;
				@include opacity(.9);
				width: 40px;
				height: 30px;
				padding: 8px;
				background-color: $secondary-site-color;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center center;
			}

			.owl-next {
				@extend .as-icon.tooltip-arrow-right;
				right: 0px;
			}

			.owl-prev {
				@extend .as-icon.tooltip-arrow-left;
				left: 0px;
			}
		}
	}

	.as-tooltip-title {
		font-size: 16px;
		color: #080e1b;
		font-weight: 500;
	}

	.as-tooltip-date {
		font-size: 11px;
		color: #a08270;
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .tooltip-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .tooltip-alpha{
		@include transform(none);
		width: 100%;
		padding-bottom: 0px;
		position: static;

		&:after {
			display: none;
		}
		
		.as-tooltip-container {
			max-height: auto;
			padding: 0px;
			background-color: #002e57;

			.owl-carousel {
				display: block;

				li {
					padding: 10px !important;
					height: auto !important;
					line-height: initial !important;

					.as-tooltip-date {
						display: none;
					}
				}
			}
		}

		.as-tooltip-title {
			margin-top: 0px !important;
			color: $secondary-site-color;
		}

		img {
			display: none;
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .tooltip-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .tooltip-alpha{

  }
}
