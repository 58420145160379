/*------------------------------------*\
    #popup-beta
\*------------------------------------*/

/*<-- GLOBAL -->*/
.popup-beta{
	@include app-transition;
	font-family: $museo;
  width: 450px;
  height: calc(100% - 220px);
  position: absolute;
  top: 120px;
  right: calc((100% - 1170px) / 2);
  z-index: 1;

  .container {
  	width: 100%;
  	position: relative;
  	height: 100%;
  }

  &.disabled {
  	height: auto;
  	top: initial;
  	bottom: 100px;
  	
  	.as-popup-container {
  		@include app-transition;
  		width: 0px;
  		height: 0px;
  		padding: 0px;
  		top: initial;
  		bottom: 0px;
  		overflow: hidden;
  	}

  	.as-popup-toggle {
  		@include opacity(1);
  		z-index: initial;
  	}
  }

	.as-popup-container {
		width: 450px;
		max-height: 100%;
		background-color: $secondary-site-color;
		padding: 64px 32px;
		position: absolute;
		right: 0px;
		overflow: auto;

		.close-gold {
			position: absolute;
			top: 32px;
			right: 32px;
		}

		.as-popup-title {
			color: #080e1b;
			font-size: 30px;
			font-weight: 500;

			&.small {
				font-size: 24px;
			}
		}

		.as-popup-subtitle {
			padding-top: 16px;
			padding-bottom: 24px;
			font-size: 16px;
			font-weight: 700;
			color: #bbaea5;
			position: relative;

			&:after {
				content: '';
				width: 48px;
				height: 1px;
				display: block;
				background-color: #bbaea5;
				position: absolute;
				left: 0px;
				bottom: 0px;
			}
		}

		.as-popup-description-container {
			width: 100%;
			height: 45%;
			max-height: 341px;
			margin-top: 24px;
			overflow: hidden;
			position: relative;

			.as-popup-description {
				width: 100%;
				height: auto;
				padding: 8px 0;
				padding-right: 24px;
				font-size: 15px;
				color: #616262;

				.as-popup-title {
					margin-top: 64px;
					margin-bottom: 16px;
					padding-bottom: 16px;
					position: relative;

					&:after {
						content: '';
						width: 48px;
						height: 1px;
						display: block;
						background-color: #bbaea5;
						position: absolute;
						left: 0px;
						bottom: 0px;
					}
				}

				.as-popup-list {
					margin: 0px;
					padding: 0px;
					font-size: 15px;

					li {
						margin-top: 16px;
						list-style: none;

						b {
							display: block;
							font-weight: bold;
							font-weight: 500;
						}

						span {
							display: block;
						}
					}
				}
			}
		}

		.as-popup-buttons-container {
			ul.as-popup-buttons {
				@include clearfix;
				margin: 24px 0 0;
				padding: 0px;

				li {
					width: 33.3%;
					padding-right: 8px;
					float: left;
					list-style: none;

					a {
						@include border-radius(3px);
						width: 100%;
						height: 50px;
						padding: 10px 10px 10px 40px;
						display: block;
						border: 1px solid #bbaea5;
						position: relative;

						&:before {
							@include vertical-center;
							content: '';
							display: block;
							position: absolute;
							left: 10px;
						}

						&.link {
							&:before {
								@extend .as-icon.link;
							}
						}

						&.video {
							&:before {
								@extend .as-icon.video-gold;
							}
						}

						&.document {
							&:before {
								@extend .as-icon.document;
							}
						}

						span {
							display: block;
							font-size: 13px;
							line-height: 1.2;
							color: #616262;

							&.gold {
								color: #bbaea5;
							}
						}
					}
				}
			}
		}
	}

	.as-popup-toggle {
		@include opacity(0);
		width: 300px;
		height: 50px;
		padding: 0 24px;
		font-size: 13px;
		font-weight: 700;
		line-height: 50px;
		color: #080e1b;
		text-transform: uppercase;
		background-color: #ffffff;
		position: absolute;
		right: 0px;
		bottom: 0px;
		z-index: -1;

		.arrow-up {
			@include vertical-center;
			position: absolute;
			right: 24px;
		}
	}
}
// Usage
@media (max-height: 891px) {
  .popup-beta{
		.as-popup-container {
			.as-popup-description-container {
				max-height: 174px;
			}
		}
  }
}

/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .popup-beta{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .popup-beta{
  	width: 100%;
		position: static;
		height: auto;
		padding: 0 16px;

		.container {
			padding: 0px;
		}

		.as-popup-container {
			width: 100%;
			position: static;
			padding: 16px 0;

			.close-gold {
				display: none;
			}

			.as-popup-description-container {
				max-height: initial;
				height: auto;

				.as-popup-description {
					font-size: 15px;
					padding-right: 0px;

					.as-popup-title {
						margin-top: 32px;
					}
				}
			}

			.as-popup-title {
				font-size: 24px;

				.as-popup-subtitle {
					font-size: 17px;
				}
			}

			.as-popup-buttons-container {
				ul.as-popup-buttons {
					li {
						a {
							padding-left: 10px;

							&:before {
								display: none;
							}
						}
					}
				}
			}
		}

		.as-popup-toggle {
			width: 100%;
			position: static;
			display: none;
		}
  }
}
/*<-- MD -->*/
@media (max-width: $screen-md-max) {
  .popup-beta{
		right: calc((100% - 970px) / 2);
  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .popup-beta{

  }
}
