/*------------------------------------*\
    #breadcrumb-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.breadcrumb-alpha{
	.as-breadcrumb-container {
		display: block;
		height: auto;
		text-align: center;
		background-color: #f6f6f6;
		font-family: $museo;

		ul.as-breadcrumb {
			@include clearfix;
			width: auto;
			display: inline-block;
			margin: 0px auto;
			padding: 12px 15px 8px;

			li {
				float: left;
				padding-right: 24px;
				list-style: none;
				position: relative;

				&:last-child {
					padding-right: 0px;

					&:after {
						display: none;
					}
				}

				&:after {
					@extend .as-icon.breadcrumb-arrow-right;
					content: '';
					display: block;
					background-repeat: no-repeat;
					position: absolute;
					top: 7px;
					right: 8px;
				}

				a {
					color: #616262;
					font-size: 12px;
				}
			}
		}
	}

	&.beta {
		.as-breadcrumb-container {
			background-color: $secondary-site-color;
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .breadcrumb-alpha{

  }
}
/*<-- SM -->*/
@media (min-width: $screen-sm-min) {
  .breadcrumb-alpha{

  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .breadcrumb-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .breadcrumb-alpha{

  }
}
