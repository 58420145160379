/*------------------------------------*\
    #header-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
$header-height: 70px;

.header-alpha{
	position: relative;
	z-index: 2;

	.header {
		height: $header-height;
		margin-top: 30px;
		background-color: $primary-site-color;
		font-family: $museo;

		.as-header-row {
			float: left;

			&.as-logo-container {
				width: 210px;
				height: $header-height;
				padding-top: 10px;
				background-color: $secondary-site-color;

				.logo {
					margin: 0 auto;
				}
			}

			&.as-menu-container {
				width: 653px;

				.hamburger,
				.hamburger-close {
					display: none;
				}

				.hamburger-icon {
					display: none;
				}
			}

			&.as-detail-container {
				width: 307px;
			}
		}

		ul.as-menu {
			height: $header-height;
			margin: 0px;
			padding: 0px;

			li {
				padding: 24px;
				list-style: none;
				text-align: center;
				float: left;
				position: relative;
				cursor: pointer;

				&.as-hide-language,
				&.as-hide-search,
				&.as-hide-tay-group {
					display: none;
					font-weight: bold;
				}

				a {
					display: block;
					color: $secondary-site-color;
					font-size: 15px;
				}

				&:hover {
					a {
						@include opacity(1);
						border-bottom: 1px solid #144977;
					}

					ul.as-sub-menu {
						display: block;
					}
				}
			}
		}

		ul.as-sub-menu {
			width: 200px;
			display: none;
			margin: 0px;
			padding: 0px;
			position: absolute;
			top: $header-height;
			left: 0px;

			li {
				width: 100%;
				height: 67px;
				padding: 0px;
				text-align: left;
				background-color: $primary-site-color;
				border-bottom: 1px solid #092741;

				a {
					padding: 24px;
					font-size: 13px;
					background-color: #001f3a;

					&:hover {
						background-color: $secondary-site-color;
						color: $primary-site-color;
					}
				}

				&:hover {
					a {
						border-bottom: none;
					}
				}
			}
		}

		.as-language-container {
			width: 117px;
			height: $header-height;
			position: relative;
			float: left;

			&:before {
				@include opacity(.1);
				content: '';
				width: 1px;
				height: 100%;
				display: block;
				background-color: $secondary-site-color;
				position: absolute;
				top: 0px;
				left: 0px;
			}

			ul.as-language {
				height: $header-height;
				margin: 0px;
				padding: 0px;

				li {
					width: 100%;
					height: $header-height;
					list-style: none;

					&:last-child {
						display: none;

						a:hover {
							background-color: $secondary-site-color;
							color: $primary-site-color;
						}
					}

					a {
						height: $header-height;
						display: block;
						padding: 27px;
						font-size: 12px;
						color: $secondary-site-color;
						background-color: $primary-site-color;

						&:hover {
							@include opacity(1);
						}

						span {
							position: relative;

							.arrow-down {
								position: absolute;
								top: 3px;
								right: -24px;
							}
						}
					}
				}

				&:hover {
					li {
						&:last-child {
							display: block;
						}
					}
				}
			}
		}

		.as-search-container {
			width: $header-height;
			height: $header-height;
			position: relative;
			float: left;
			cursor: pointer;

			&:before {
				@include opacity(.1);
				content: '';
				width: 1px;
				height: 100%;
				display: block;
				background-color: $secondary-site-color;
				position: absolute;
				top: 0px;
				left: 0px;
			}

			.search {
				position: absolute;
		    left: 25px;
		    top: 27px;
			}

			.tooltip-beta {
				bottom: initial;
				top: 80px;

				&:after {
					@include transform(rotate(180deg));
					bottom: initial;
					top: -10px;
				}
			}
		}

		.as-tay-group-logo {
			width: 120px;
			height: $header-height;
			padding-top: 24px;
			background-color: #002e57;
			float: left;

			.tay-group {
				margin: 0 auto;
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .header-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .header-alpha{
  	.header {
  		width: 100%;
  		height: 60px;
			margin-top: 0px;
			.as-header-row {
				&.as-logo-container{
					width: 150px;
					height: 60px;
					padding-top: 5px;
				}

				&.as-menu-container {
					.hamburger-icon,
					.hamburger-close {
						width: 60px;
						height: 60px;
						display: block;
						background-position: center;
						position: absolute;
						top: 0px;
						right: 0px;
					  -webkit-transform: rotate(0deg);
					  -moz-transform: rotate(0deg);
					  -o-transform: rotate(0deg);
					  transform: rotate(0deg);
					  -webkit-transition: .5s ease-in-out;
					  -moz-transition: .5s ease-in-out;
					  -o-transition: .5s ease-in-out;
					  transition: .5s ease-in-out;
					  cursor: pointer;

						span {
						  display: block;
						  position: absolute;
						  height: 2px;
						  width: 30px;
						  background: $secondary-site-color;
						  opacity: 1;
						  left: 15px;
						  -webkit-transform: rotate(0deg);
						  -moz-transform: rotate(0deg);
						  -o-transform: rotate(0deg);
						  transform: rotate(0deg);
						  -webkit-transition: .25s ease-in-out;
						  -moz-transition: .25s ease-in-out;
						  -o-transition: .25s ease-in-out;
						  transition: .25s ease-in-out;
						}

						span:nth-child(1) {
						  top: 21px;
						}

						span:nth-child(2),
						span:nth-child(3) {
						  top: 27px;
						}

						span:nth-child(4) {
						  top: 33px;
						}

						&.active span:nth-child(1) {
						  top: 21px;
						  width: 0%;
						  left: 50%;
						}

						&.active span:nth-child(2) {
						  -webkit-transform: rotate(45deg);
						  -moz-transform: rotate(45deg);
						  -o-transform: rotate(45deg);
						  transform: rotate(45deg);
						}

						&.active span:nth-child(3) {
						  -webkit-transform: rotate(-45deg);
						  -moz-transform: rotate(-45deg);
						  -o-transform: rotate(-45deg);
						  transform: rotate(-45deg);
						}

						&.active span:nth-child(4) {
						  top: 21px;
						  width: 0%;
						  left: 50%;
						}

						&.active {
							background-color: #002e57;
						}
					}

					.as-menu {
						width: 100%;
						height: auto;
						display: none;
						padding: 16px 16px 0;
						background-color: #002e57;
						position: absolute;
						top: 60px;
						left: 0px;

						&.active {
							display: block;
						}

						li {
							padding: 12px;
							padding-left: 0px;
							padding-right: 0px;
							float: none;
							position: relative;
							text-align: left;

							a {
								font-size: 18px;
							}

							&.as-hide-language {
								display: block;
								border-bottom: none;

								&:after {
									display: none;
								}
							}

							&.as-hide-search {
								width: 100%;
								display: block;
								margin-top: 0px;
								position: relative;

								&:after {
									display: none;
								}

								input {
									width: 100%;
									height: 50px;
									padding: 0 36px 0 24px;
									font-size: 12px;
									border: none;
									outline: none;
								}

								.as-icon {
									@include vertical-center;
									position: absolute;
									right: 24px;
								}
							}

							&.as-hide-tay-group {
								display: block;
								padding-top: 0px;

								&:after {
									display: none;
								}

								.as-image {
									margin: 18px auto;
								}
							}

							&:after {
								@include opacity(.1);
								content: '';
								width: 100%;
								height: 1px;
								display: block;
								background-color: $secondary-site-color;
								position: absolute;
								left: 0px;
								bottom: 0px;
							}

							&:hover {
								a {
									border-bottom: none;
								}

								ul.as-sub-menu {
									display: none;
								}
							}
						}
					}
				}

				&.as-detail-container {
					display: none;
				}
			}
  	}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .header-alpha{
		.header {
			.as-header-row {
				&.as-menu-container {
					width: 453px;
				}

				ul.as-menu {
					padding-left: 15px;

					li {
						padding: 27px 10px;

						a {
							font-size: 12px;
						}
					}

					ul.as-sub-menu li {
						padding: 0px;

						a {
							padding: 27px 10px;
							border-bottom: none !important;
						}
					}
				}
			}
		}
  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .header-alpha{
		
  }
}
