/*------------------------------------*\
    #map-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.map-alpha{
	.as-map-container {
		margin-bottom: 64px;
		border: 24px solid #f6f6f6;

		iframe {
			width: 100%;
			display: block;
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .map-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .map-alpha{
		.as-map-container {
			margin-bottom: 32px;
			border: 8px solid #f6f6f6;
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .map-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .map-alpha{

  }
}
