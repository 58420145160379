/*------------------------------------*\
    #projects-boxes-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.projects-boxes-alpha{
	.as-project-container {
		font-family: $museo;

		ul.as-projects {
			@include clearfix;
			margin: 0px auto;
			padding: 48px 0;

			li {
				padding: 12px;
				list-style: none;

				a {
					display: block;
					padding: 24px;
					background-color: #f6f6f6;
					position: relative;
					overflow: hidden;

					&:hover {
						img {
							@include app-transition;
							@include transform(scale(1.2));
						}
					}

					.as-project-image {
						display: block;
						overflow: hidden;
					}

					img {
						@include app-transition;
						width: 100%;
						display: block;
					}
				}

				.as-projects-title {
					height: 72px;
					display: block;
					margin: 24px -24px 0;
					padding: 16px 24px 0;
					font-size: 17px;
					font-weight: 700;
					color: #616262;
					text-align: left;
					color: #616262;
					border-top: 2px solid $secondary-site-color;
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .projects-boxes-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .projects-boxes-alpha{
		.as-project-container {
	  	ul.as-projects {
				width: 100%;
				padding: 12px 0 0;

				li {
					padding: 12px 0;

					.as-projects-title {
						font-size: 18px;
					}
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .projects-boxes-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .projects-boxes-alpha{

  }
}
