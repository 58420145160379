/*------------------------------------*\
    #vertical-menu-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.vertical-menu-alpha{
	.as-vertical-menu-container {
		font-family: $museo;
		padding: 59px 0;

		.as-vertical-menu-title {
			display: block;
			padding: 24px;
			color: #002e57;
			font-size: 20px;
			font-weight: 700;
			background-color: #eceeef;
		}

		ul.as-vertical-menu {
			@include clearfix;
			margin: 0px auto;
			padding: 0 24px 48px;
			background-color: #f6f6f6;

			li {
				font-size: 15px;
				list-style: none;

				a {
					height: 45px;
					display: block;
					padding-top: 12px;
					color: #616262;

					&:hover,
					&.active {
						color: #3c9cf2;
					}

					&.active {
						& + .submenu {
							display: block;
						}
					}
				}
			}

			.submenu {
				padding: 8px 24px 16px;
				margin: 0px -24px;
				display: none;
				background-color: $secondary-site-color;

				li {
					a {
						height: auto;
						font-size: 13px;
					}
				}
			}
		}
	}

	&.beta {
		.as-vertical-menu-container {
			.as-vertical-menu-title {
				background-color: $secondary-site-color;
				border-bottom: 3px solid #f2f4f4;
				color: #002e57;
			}

			ul.as-vertical-menu {
				background-color: $secondary-site-color;
				padding-bottom: 0px;

				li {
					margin: 0 -24px;
					padding: 0 24px;
					border-bottom: 2px solid #f2f4f4;

					&:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .vertical-menu-alpha{
		.as-vertical-menu-container {
			padding: 16px 0 32px;
		}
  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .vertical-menu-alpha{

  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .vertical-menu-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .vertical-menu-alpha{

  }
}
