/*------------------------------------*\
    #company-row-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.company-row-alpha{
	font-family: $museo;
	font-weight: 500;

	&:last-child {
		margin-bottom: 64px;
	}

	.as-company-row {
		margin-bottom: 24px;

		.as-company-title {
			margin-top: 59px;
			margin-bottom: 16px;
			font-size: 30px;
			font-family: $museo;
			font-weight: 500;
			font-weight: 500;
			color: #3c9cf2;
		}

		.as-company-subtitle {
			margin-bottom: 16px;
			font-size: 24px;
			font-family: $museo;
			font-weight: 500;
			font-weight: 700;
			color: #616262;
		}

		.as-company-description {
			margin-bottom: 16px;
			font-size: 17px;
			color: #616262;

			p {
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}

		.as-company-link {
			a {
				text-decoration: underline;
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .company-row-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .company-row-alpha{
		&:last-child {
			margin-bottom: 0px;
		}

		.as-company-row {
			margin-bottom: 16px;

			.as-company-title {
				margin-top: 24px;
				font-size: 24px;
			}

			.as-company-subtitle {
				font-size: 20px;
			}

			.as-company-description {
				font-size: 15px;
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .company-row-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .company-row-alpha{

  }
}
