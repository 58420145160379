/*------------------------------------*\
    #accordion-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.accordion-alpha{
	font-family: $museo;

	.as-accordion-container {
			.as-accordion-title {
				padding: 24px 0 24px;
				font-size: 30px;
				font-family: $museo;
				font-weight: 500;
				color: #3c9cf2;
			}

			.as-accordion {
				.as-accordion-row {
					margin-bottom: 5px;

					a.accordion-toggle {
						@include app-transition;
						width: 100%;
						height: 55px;
						display: block;
						padding: 15px 24px 0;
						color: #616262;
						font-size: 18px;
						font-weight: 700;
						background-color: #f6f6f6;
						position: relative;

						&:after {
							@extend .as-icon.accordion-arrow-down;
							@include vertical-center;
							content: '';
							display: block;
							position: absolute;
							right: 24px;
						}

						&.active {
							@include app-transition;
							color: #3c9cf2;

							&:after {
								@extend .as-icon.accordion-arrow-up;
							}

							& + .as-accordion-description {
								@include app-transition;
								@include opacity(1);
								padding: 32px 24px;
								max-height: 1000px;
							}
						}
					}

					.as-accordion-description {
						@include app-transition;
						@include opacity(0);
						max-height: 0px;
						color: #616262;
						background-color: #f6f6f6;
						font-size: 15px;
						overflow: hidden;

						p {
							margin-bottom: 24px;
						}
					}

					.as-accordion-link {
						margin-top: 16px;
						font-size: 14px;
						font-weight: 700;
						color: #3c9cf2;
						text-decoration: underline;
					}
				}
			}
		}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .accordion-alpha{
		
  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .accordion-alpha{
		.as-accordion-container {
			.as-accordion-title {
				padding: 16px 0;
				font-size: 24px;
			}

			.as-accordion {
				.as-accordion-row {
					a.accordion-toggle {
						padding: 17px 16px;
						font-size: 15px;

						&.active + .as-accordion-description {
							padding: 16px;

							p {
								margin-bottom: 16px;
							}
						}
					}
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .accordion-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .accordion-alpha{

  }
}
