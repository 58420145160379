//Its important to use bootstrap icons
$icon-font-path: '../fonts/';
@import "icons";
@import "hacks";

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "bourbon";
//custom mixins
@import "mixins";
//custom colors
@import "colors";
//custom images
@import "images";
//custom fonts
@import "fonts";
//custom styles
@import "common";
@import "fancybox.scss";

//Importing All Modules
@import "f:/wwwroot/astay/HTML/app/modules/accordion-alpha/accordion-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/bar-alpha/bar-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/breadcrumb-alpha/breadcrumb-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/breadcrumb-beta/breadcrumb-beta.scss";
@import "f:/wwwroot/astay/HTML/app/modules/caption-alpha/caption-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/carousel-alpha/carousel-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/company-row-alpha/company-row-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/contact-info-alpha/contact-info-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/corporate-alpha/corporate-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/footer-alpha/footer-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/form-alpha/form-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/form-beta/form-beta.scss";
@import "f:/wwwroot/astay/HTML/app/modules/form/form.scss";
@import "f:/wwwroot/astay/HTML/app/modules/header-alpha/header-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/map-alpha/map-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/modal-alpha/modal-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/modal-beta/modal-beta.scss";
@import "f:/wwwroot/astay/HTML/app/modules/new-boxes-alpha/new-boxes-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/popup-alpha/popup-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/popup-beta/popup-beta.scss";
@import "f:/wwwroot/astay/HTML/app/modules/project-detail-alpha/project-detail-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/project-media-alpha/project-media-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/projects-alpha/projects-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/projects-boxes-alpha/projects-boxes-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/promo-alpha/promo-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/tooltip-alpha/tooltip-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/tooltip-beta/tooltip-beta.scss";
@import "f:/wwwroot/astay/HTML/app/modules/utils/utils.scss";
@import "f:/wwwroot/astay/HTML/app/modules/vertical-menu-alpha/vertical-menu-alpha.scss";
@import "f:/wwwroot/astay/HTML/app/modules/welcome/welcome.scss";


// Layout Kodları
.popup-beta.disabled .as-popup-toggle {
  overflow: hidden;
}
.carousel-alpha .as-carousel .owl-carousel li .as-carousel-text {
  text-shadow: 2px 2px 14px #000;
}
.four-seasons-sultan{
  //background-image: url(http://astay.gricreative.com/images/footer-alpha/four-seasons-sultan-ahmet.png)!important;
}
.modal-body {
  text-align: center;
}
.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
}

.popup-beta .as-popup-container {
  padding: 64px 32px 44px;
}
.as-popup-buttons-container{
  padding-bottom: 20px;
}

[tpl]{
  display: none !important;
}
