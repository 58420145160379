/*------------------------------------*\
    #project-media-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.project-media-alpha{
	.as-project-media-container {
		padding-bottom: 64px;
		
		.as-project-media {
			.as-project-media-image {
				img {
					width: 100%;
					display: block;
				}
			}

			.as-project-media-list {
				@include clearfix;
				padding: 0px;
				margin: 0px;

				li {
					width: 33.3%;
					list-style: none;
					float: left;
					background-color: $primary-site-color;
					border-right: 1px solid #002444;

					a {
						padding: 16px 0;
						display: block;
						font-size: 16px;
						text-align: center;
						color: $secondary-site-color;

						span {
							padding-left: 32px;
							position: relative;

							.as-icon {
								position: absolute;
								top: 0px;
								left: 0px;
							}
						}
					}
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .project-media-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .project-media-alpha{
		.as-project-media-container {
			padding-bottom: 16px;

			.as-project-media {
				.as-project-media-list {
					li {
						width: 100%;
						float: none;
					}
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .project-media-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .project-media-alpha{

  }
}
