/*------------------------------------*\
    #popup-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.popup-alpha{
	width: 307px;
  position: absolute;
  top: 148px;
  right: calc((100% - 1170px) / 2);
  z-index: 1;
  	
	.as-popup-container {
		padding: 16px;
		background-color: $secondary-site-color;

		.as-popup-title {
			font-size: 16px;
			color: #080e1b;
			font-weight: 700;
		}

		.as-popup-date {
			font-size: 11px;
			color: #3c9cf2;
			font-weight: 700;
		}

		.as-popup-close {
			width: 40px;
			height: 40px;
			display: block;
			background-color: $primary-site-color;
			position: absolute;
			top: 0px;
			right: 0px;

			.as-icon {
				@include opacity(1);
				@include all-center;
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .popup-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .popup-alpha{
    display: none;
  }
}
/*<-- MD -->*/
@media (max-width: $screen-md-max) {
  .popup-alpha{
		right: calc((100% - 970px) / 2);
  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .popup-alpha{

  }
}
