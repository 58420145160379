/*------------------------------------*\
    #form-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.form-alpha{
	font-family: $museo;

	.form-container {
		padding-bottom: 64px;

		.form-title {
			padding: 24px 0 24px;
			font-size: 30px;
			font-family: $museo;
			font-weight: 500;
			color: #3c9cf2;
		}
		
		.form-row {
			@include clearfix;

			.as-input {
				width: 100%;
				height: 55px;
				display: block;
				padding: 0 24px;
				margin-bottom: 5px;
				font-family: $museo;
				font-weight: 500;
				font-size: 17px;
				border: none;
				outline: none;
				background-color: #eceeef;

				&.light {
					background-color: #fff;
					border: 4px solid #eceeef;
				}

				&.file {
					padding-top: 13px;
					background-color: #fff;
					border: 4px solid #eceeef;
				}

				&.textarea {
					padding: 24px;
					height: 105px;
					resize: none;
				}

				&.error {
					border: 1px solid #ec1515;
				}
			}

			.as-button {
				width: 220px;
				height: 55px;
				display: block;
				border: none;
				outline: none;
				color: $secondary-site-color;
				background-color: #3c9cf2;
				text-align: center;
				font-family: $museo;
				font-weight: 900;
			}

			.as-file-input {
				position: relative;

				.as-input {
					&.file {
						@include opacity(0);
						position: absolute;
						top: 0px;
						left: 0px;
						z-index: 1;
					}
				}

				.as-icon {
					@include vertical-center;
					right: 24px;
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .form-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .form-alpha{
		.form-container {
			padding-bottom: 24px;
			
			.form-title {
				padding: 16px 0;
				font-size: 24px;
			}

			.form-row {
				margin-top: 0px !important;

				.as-button {
					width: 100%;
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .form-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .form-alpha{

  }
}
