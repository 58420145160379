/*------------------------------------*\
    #form-beta
\*------------------------------------*/

/*<-- GLOBAL -->*/
.form-beta{
	font-family: $museo;

	.as-form-container {
		@include clearfix;
		padding: 59px 0px;

		.as-form-title {
			padding: 0 0 24px;
			font-size: 30px;
			font-weight: 500;
			color: #3c9cf2;
		}
		
		.as-form-row {
			@include clearfix;

			.as-input {
				width: 100%;
				height: 55px;
				display: block;
				padding: 0 24px;
				margin-bottom: 5px;
				font-weight: 500;
				font-size: 17px;
				border: none;
				outline: none;
				background-color: #eceeef;

				&.light {
					background-color: #fff;
					border: 4px solid #eceeef;
				}

				&.file {
					padding-top: 13px;
					background-color: #fff;
					border: 4px solid #eceeef;
				}

				&.textarea {
					padding: 24px;
					height: 105px;
					resize: none;
				}

				&.error {
					border: 1px solid #ec1515;
				}
			}

			.as-button {
				width: 220px;
				height: 55px;
				display: block;
				border: none;
				outline: none;
				color: $secondary-site-color;
				background-color: #3c9cf2;
				text-align: center;
				font-weight: 900;
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .form-beta{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .form-beta{
		.as-form-container {
			padding: 24px 0px;

			.as-form-title {
				padding-bottom: 16px;
				font-size: 24px;
				color: #3c9cf2;
			}

			.as-form-row {
				width: 100%;
				margin-top: 0px !important;

				.as-button {
					width: 100%;
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .form-beta{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .form-beta{

  }
}
