/*------------------------------------*\
    #contact-info-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.contact-info-alpha{
	font-family: $museo;

	.as-contact-info-container {
		padding: 59px 0;

		.as-contact-info-title {
			padding-bottom: 24px;
			font-size: 30px;
			font-family: $museo;
			font-weight: 500;
			color: #3c9cf2;
		}

		.as-contact-info-row {
			margin-bottom: 24px;
			font-size: 17px;
			color: #616262;

			&:last-child {
				margin-bottom: 0px;
			}

			.as-contact-info {
				padding-left: 40px;
				position: relative;

				b {
					display: block;
					font-family: $museo;
					font-weight: 500;
				}

				&:after {
					content: '';
					display: block;
					left: 0px;
					position: absolute;
					top: 3px;
				}

				&.pin {
					&:after {
						@extend .as-icon.pin;
					}
				}

				&.phone {
					&:after {
						@extend .as-icon.phone;
					}
				}

				&.fax {
					&:after {
						@extend .as-icon.fax;
					}
				}

				&.mail {
					&:after {
						@extend .as-icon.mail;
						top: 5px;
					}
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .contact-info-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .contact-info-alpha{
		.as-contact-info-container {
			padding: 24px 0 8px;

			.as-contact-info-title {
				padding-bottom: 16px;
				font-size: 24px;
			}

			.as-contact-info-row {
				margin-bottom: 16px;
				font-size: 15px;
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .contact-info-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .contact-info-alpha{

  }
}
