$museo:'Museo Sans';
$museo500:'MuseoSans500';
$museo700:'MuseoSans700';
$museo900:'MuseoSans900';

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/museo-sans-300/MuseoSans300.eot');
  src: url('../fonts/museo-sans-300/MuseoSans300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/museo-sans-300/MuseoSans300.woff') format('woff'),
  url('../fonts/museo-sans-300/MuseoSans300.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/museo-sans-500/MuseoSans500.eot');
  src: url('../fonts/museo-sans-500/MuseoSans500.eot?#iefix') format('embedded-opentype'),
  url('../fonts/museo-sans-500/MuseoSans500.woff') format('woff'),
  url('../fonts/museo-sans-500/MuseoSans500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/museo-sans-700/MuseoSans700.eot');
  src: url('../fonts/museo-sans-700/MuseoSans700.eot?#iefix') format('embedded-opentype'),
  url('../fonts/museo-sans-700/MuseoSans700.woff') format('woff'),
  url('../fonts/museo-sans-700/MuseoSans700.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/museo-sans-900/MuseoSans900.eot');
  src: url('../fonts/museo-sans-900/MuseoSans900.eot?#iefix') format('embedded-opentype'),
  url('../fonts/museo-sans-900/MuseoSans900.woff') format('woff'),
  url('../fonts/museo-sans-900/MuseoSans900.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}