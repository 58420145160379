/*------------------------------------*\
    #footer-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
$footer-top-height: 140px;
$footer-bottom-height: 120px;

.footer-alpha {
	.as-footer-top {
		width: 100%;
		background-color: #f6f6f6;

		ul.as-footer-logo {
			@include clearfix;
			width: 720px;
			margin: 0px auto;
			padding: 0px;

			li {
				height: $footer-top-height;
				float: left;
				list-style: none;

				a {
					width: $footer-top-height;
					height: $footer-top-height;
					display: block;
					margin: 0 auto;
					position: relative;
					
					.as-image {
						@include all-center;
					}
				}
			}
		}
	}

	.as-footer-bottom {
		width: 100%;
		height: $footer-bottom-height;
		text-align: center;
		font-family: $museo;
		font-size: 14px;

		.as-copyright {
			color: #080e1b;
		}

		.as-company {
			display: inline-block;
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .footer-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .footer-alpha{
  	.as-footer-top {
			ul.as-footer-logo {
				width: 100%;

				li {
					height: 100px;

					a {
						height: 100px;
					}
				}
			}
  	}

  	.as-footer-bottom {
			height: auto;
			margin-bottom: 40px;

			.as-copyright {
				padding: 0 64px;
			}
  	}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .footer-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .footer-alpha{

  }
}
