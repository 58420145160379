/*------------------------------------*\
    #utils
\*------------------------------------*/

/*<-- GLOBAL -->*/
.utils{

}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .utils{

  }
}
/*<-- SM -->*/
@media (min-width: $screen-sm-min) {
  .utils{

  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .utils{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .utils{

  }
}
