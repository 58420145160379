/*------------------------------------*\
    #caption-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.caption-alpha{
	.caption-alpha-container {
		font-family: $museo;

		.caption-title {
			padding: 59px 0 32px;
			font-size: 30px;
			font-family: $museo;
      font-weight: 500;
			color: #3c9cf2;
		}

		.caption-description {
			font-size: 17px;
			color: #616262;

			a {
				color: #3c9cf2;
				font-family: $museo;
        font-weight: 700;
			}

			p {
				margin-bottom: 24px;

				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .caption-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .caption-alpha{
		.caption-alpha-container {
			.caption-title {
				padding: 16px 0;
				font-size: 24px;
			}

			.caption-description {
				font-size: 15px;

				p {
					margin-bottom: 16px;
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .caption-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .caption-alpha{

  }
}
