
/* ----------------------------------- //
//      Custom Mixins
// ----------------------------------- //*/

/*Cover Background Mixin*/

@mixin cover-background($img-uri) {
    background: url($img-uri) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin horizontal-center {
  position: absolute;
  left: 50%;
  @include transform(translateX(-50%));
}

@mixin all-center {
  position: absolute;
  top: 50%;
  left: 50%; 
  @include transform(translateY(-50%) translateX(-50%));
}

@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

@mixin app-transition($transition: all .3s ease-out) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}