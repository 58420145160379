/*------------------------------------*\
    #form
\*------------------------------------*/

/*<-- GLOBAL -->*/
.form{
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .form{

  }
}
/*<-- SM -->*/
@media (min-width: $screen-sm-min) {
  .form{

  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .form{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .form{

  }
}
