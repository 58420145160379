a, a:hover {
	@include app-transition;
	text-decoration: none;
	cursor: pointer;
	outline: none;
}

a:hover {
	@include opacity(.7);
}

.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  background-color: #bbaea5;
}

.as-popup-description-container {
  .ps__scrollbar-y-rail {
    opacity: 1 !important;
  }
}

.container {
	&.small {
		width: 940px;
		margin: 0 auto;
	}
}

@media (max-width: $screen-sm-max) {
	.container {
		&.small {
			width: 100%;
			margin: 0 auto;
		}
	}
}