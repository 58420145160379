/*------------------------------------*\
    #project-detail-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.project-detail-alpha{
	.as-project-detail-container {
		padding: 59px 0;
		font-family: $museo;

		.as-project-detail {
			.as-project-title {
				font-size: 30px;
				font-weight: 500;
				color: #3c9cf2;
			}

			.as-project-sub-title {
				margin-top: 24px;
				font-size: 20px;
				color: #616262;
				font-style: italic;
			}

			.as-project-description {
				color: #616262;
				font-size: 17px;

				p {
					margin-top: 24px;

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .project-detail-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .project-detail-alpha{
		.as-project-detail-container {
			padding: 16px 0;

			.as-project-detail {
				.as-project-title {
					font-size: 24px;
				}

				.as-project-sub-title {
					margin-top: 16px;
					font-size: 17px;
				}

				.as-project-description {
					font-size: 15px;

					p {
						margin-top: 16px;
					}
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .project-detail-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .project-detail-alpha{

  }
}
