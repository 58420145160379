/*------------------------------------*\
    #corporate-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.corporate-bg-container {
	background-image: url('../images/corporate-alpha/bg.png');
	background-repeat: no-repeat;
	background-size: cover;
}
.corporate-alpha{

	.as-corporate-container {
		padding: 59px 0;
		font-family: $museo;

		.as-corporate {
			.as-corporate-title {
				font-size: 30px;
				color: #3c9cf2;
				font-family: $museo;
				font-weight: 500;
			}

			.as-corporate-sub-title {
				margin-top: 24px;
				font-size: 20px;
				color: #616262;
				font-style: italic;
			}

			.as-corporate-description {
				color: #616262;
				font-size: 17px;

				img {
					float: right;
					margin: 0 0 24px 24px;
				}

				p {
					margin-top: 24px;

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .corporate-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .corporate-alpha{
		.as-corporate-container {
			padding: 16px 0;

			.as-corporate {
				.as-corporate-title {
					font-size: 24px;
				}
				
				.as-corporate-sub-title {
					margin-top: 16px;
					font-size: 17px;
				}

				.as-corporate-description {
					img {
						width: 100%;
						display: block;
						margin: 16px 0px 0;
						float: none;
					}

					p {
						margin-top: 16px;
					}
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .corporate-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .corporate-alpha{

  }
}
