/*------------------------------------*\
    #projects-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.projects-alpha{
	.as-project-container {
		font-family: $museo;

		ul.as-projects {
			@include clearfix;
			width: 930px;
			margin: 0px auto;
			padding: 48px 0;

			li {
				margin-bottom: 12px;
				padding: 12px;
				list-style: none;

				a {
					display: block;
					padding: 24px;
					background-color: #f6f6f6;
					position: relative;
					overflow: hidden;

					&:hover {
						img {
							@include app-transition;
							@include transform(scale(1.2));
						}
					}

					.as-project-image {
						display: block;
						overflow: hidden;
					}

					img {
						@include app-transition;
						width: 100%;
						display: block;
					}
				}

				.as-projects-title {
					@include all-center;
					width: 100%;
					display: block;
					padding: 0 64px;
					font-size: 40px;
					font-weight: 700;
					text-align: center;
					color: $secondary-site-color;
					position: absolute;
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .projects-alpha{
		.as-project-container {
	  	ul.as-projects {
				li {
					.as-projects-title {
						padding: 0 32px;
						font-size: 24px;
					}
				}
			}
		}
  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .projects-alpha{
  	.as-project-container {
	  	ul.as-projects {
				width: 100%;
				padding: 24px 0;

				li {
					padding: 8px;
					
					.as-projects-title {
						padding: 0 32px;
					}
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .projects-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .projects-alpha{

  }
}
