.fancybox-container {
	.fancybox-bg {
		background: $primary-site-color;
	}

	.fancybox-controls {
		position: relative;
		height: 100%;

		.fancybox-infobar {
			@include all-center;
			width: 1170px;
		}

		.fancybox-infobar__body,
		.fancybox-button--play,
		.fancybox-button--fullscreen,
		.fancybox-button--thumbs {
			display: none !important;
		}

		.fancybox-button {
			@include border-radius(none !important);
			background-color: none;
			background: none;
			border: 1px solid $secondary-site-color;

			&:after {
				@include all-center;
				border: none;
			}
		}

		.fancybox-button--close {
			border: none;

			&:after {
				@extend .as-icon.popup-close;
				background-color: transparent;
			}

			&:before {
				display: none;
			}
		}

		.fancybox-button--left {
			border-bottom-left-radius: 0px;
			position: absolute;
			left: 0px;

			&:after {
				@extend .as-icon.carousel-arrow-left;
			}
		}

		.fancybox-button--right {
			border-bottom-right-radius: 0px;
			position: absolute;
			right: 0px;

			&:after {
				@extend .as-icon.carousel-arrow-right;
			}
		}
	}
}

@media (max-width: $screen-md-max) {
	.fancybox-infobar {
		width: 100% !important;

		.fancybox-button--right {
			right: 32px !important;
		}

		.fancybox-button--left {
			left: 32px !important;
		}
	}
}