/*------------------------------------*\
    #carousel-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.carousel-alpha{
	height: 100vh;
	margin-top: -100px;

	.carousel-alpha-carousel {
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.popup-alpha {
		position: absolute;
		top: 100px;
		right: 0px;
	}
	
	.as-carousel {
		.owl-carousel {
			margin: 0px;
			padding: 0px;

			.owl-nav {
				@include all-center;
				width: 1170px;
				height: 0px;

				&.small {
					@include transform(none);
					width: 667px;
					left: calc((100% - 1170px) / 2);
				}

				.owl-next,
				.owl-prev {
					position: absolute;
					top: 0px;
				}

				.owl-next {
					@extend .as-icon.carousel-arrow-right;
					right: 0px;
				}

				.owl-prev {
					@extend .as-icon.carousel-arrow-left;
					left: 0px;
				}
			}

			li {
				list-style: none;
				height: 100vh;
				overflow: hidden;

				img {
					display: block;
					max-width: 100%;
					min-height: 100%;
				}

				.as-carousel-text {
					@include all-center;
					width: 680px;
					display: block;
					font-size: 50px;
					font-family: $museo;
					font-weight: 500;
					color: $secondary-site-color;
					text-align: center;
				}
			}
		}

		.as-error-container {
			.as-error-title {
				font-size: 150px;
				font-family: $museo;
				font-weight: 500;
				padding-bottom: 48px;
				position: relative;
				line-height: 1;

				&:after {
					@include horizontal-center;
					content: '';
					width: 64px;
					height: 1px;
					background-color: $secondary-site-color;
					position: absolute;
					bottom: 0px;
				}
			}

			.as-error-description {
				margin-top: 32px;
				font-size: 20px;
				font-family: $museo;
				text-align: center;
			}

			.as-error-button {
				width: 200px;
				height: 50px;
				display: block;
				margin: 24px auto 0;
				line-height: 50px;
				font-size: 14px;
				font-family: $museo;
				font-weight: 900;
				text-align: center;
				color: $secondary-site-color;
				background-color: #3e9df2;
				border: none;
				outline: none;
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .carousel-alpha{

  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .carousel-alpha{
		height: auto;
		margin-top: initial;
		overflow: hidden;

		.carousel-alpha-carousel {
			height: auto;
			position: initial;

			.as-carousel {
				.owl-carousel {
					li {
						height: auto;

						.as-carousel-text {
							width: 100%;
							padding: 0 64px;
							font-size: 24px;
						}
					}

					.owl-nav {
						width: 100% !important;
						top: initial;
						bottom: 64px;
						left: initial !important;

						.owl-prev {
							left: 32px;
						}

						.owl-next {
							right: 32px;
						}
					}
				}
			}

			.as-error-container {
				.as-error-title {
					padding-bottom: 16px;
    			font-size: 32px;
				}

				.as-error-description {
					margin: 0 -48px;
			    margin-top: 16px;
			    font-size: 12px;
				}

				.as-error-button {
					width: 120px;
			    height: 32px;
			    margin: 16px auto 0;
			    line-height: 32px;
				}
			}
		}
  }
}
/*<-- MD -->*/
@media (max-width: $screen-md-max) {
  .carousel-alpha{
		.as-carousel {
			.owl-carousel {
				.owl-nav {
					width: 970px;

					&.small {
						@include transform(none);
						width: 475px;
						left: calc((100% - 970px) / 2);
					}
				}
			}
		}
  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .carousel-alpha{

  }
}
