/*------------------------------------*\
    #new-boxes-alpha
\*------------------------------------*/

/*<-- GLOBAL -->*/
.new-boxes-alpha{
	.as-box-container {
		font-family: $museo;

		ul.as-box {
			@include clearfix;
			margin: 0px auto;
			padding: 48px 0;

			li {
				padding: 12px;
				list-style: none;

				a {
					display: block;
					padding: 24px;
					background-color: #f6f6f6;
					position: relative;
					overflow: hidden;

					&:hover {
						img {
							@include app-transition;
							@include transform(scale(1.2));
						}
					}

					.as-box-image {
						display: block;
						overflow: hidden;
					}

					img {
						@include app-transition;
						width: 100%;
						display: block;
						height: 220px;
					}
				}

				.as-box-name {
					margin: 24px -24px 0;
					padding: 16px 24px 0;
					display: block;
					color: #3c9cf2;
					font-size: 13px;
					font-weight: 700;
					border-top: 2px solid $secondary-site-color;
				}

				.as-box-title {
					height: 52px;
					display: block;
					font-size: 17px;
					font-weight: 700;
					color: #616262;
					text-align: left;
					color: #616262;
					overflow: hidden;
				}

				.as-box-date {
					display: block;
					color: #616262;
					font-size: 13px;
					font-weight: 500;
				}
			}
		}
	}
}
// Usage
/*<-- XS -->*/
@media (max-width: $screen-xs-max) {
  .new-boxes-alpha{
  	.as-box-container {
			ul.as-box {
				padding: 12px 0 0;

				li {
					padding: 12px 0;
				}
			}
		}
  }
}
/*<-- SM -->*/
@media (max-width: $screen-sm-max) {
  .new-boxes-alpha{
		.as-box-container {

		}
  }
}
/*<-- MD -->*/
@media (min-width: $screen-md-min) {
  .new-boxes-alpha{

  }
}
/*<-- LG -->*/
@media (min-width: $screen-lg-min) {
  .new-boxes-alpha{

  }
}
